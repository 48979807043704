import { Injectable } from '@angular/core';
import { PaymentDetailsApi, PaymentDetailsUi } from '@core/models/payment.model';
import { FinanceResources } from '@core/resources/finance.resources';
import { APIResult, PaginationOptions } from '@yourcause/common';
import { DateService, TIMESTAMP_FORMAT } from '@yourcause/common/date';
import { TableDataDownloadFormat } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { getYear, subYears } from 'date-fns';
import { AssetManagementService } from '../../asset-management/services/asset-management.service';
import { Transaction, TransactionStatus } from '../models/donation.model';
import { MultiplePaymentsPayload, PaymentTypes } from '../models/finance.model';
import { FinanceState } from '../state/finance.state';

@AttachYCState(FinanceState)
@Injectable({ providedIn: 'root' })
export class FinanceService extends BaseYCService<FinanceState> {
  readonly disbursementStatusColumn = 'disbursementStatus';
  readonly paymentNumberColumn = 'paymentNumber';
  readonly dateCreatedColumn = 'dateCreated';

  PaymentTypesMap = {
    [PaymentTypes.CHECK]: this.i18n.translate('give:textCheck', {}, 'Check'),
    [PaymentTypes.WIRE]: this.i18n.translate('give:textWire', {}, 'Wire'),
    [PaymentTypes.ACH]: this.i18n.translate('give:textACH', {}, 'ACH'),
    [PaymentTypes.OTHER]: this.i18n.translate('give:textOther', {}, 'Other')
  };
  errorRetrievingDataText = this.i18n.translate(
    'common:notificationErrorRetreivingData',
    {},
    'There was an error retrieving the data'
  );

  constructor (
    private financeResources: FinanceResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private dateService: DateService,
    private assetManagementService: AssetManagementService
  ) {
    super();
  }

  get pendingDisbursements () {
    return this.get('pendingDisbursements');
  }

  get matchRequests () {
    return this.get('matchRequests');
  }

  get paymentId () {
    return this.get('paymentId');
  }

  get paymentNumber () {
    return this.get('paymentNumber');
  }

  get transactionsStatus () {
    return this.get('transactionsStatus');
  }

  get paymentDetailsForTransactions () {
    return this.get('paymentDetailsForTransactions');
  }

  get defaultDateRange () {
    const diff = getYear(new Date()) - 2015; // always go back to 2015

    return [
      subYears(new Date(), diff),
      new Date()
    ];
  }

  get errorState (): boolean {
    return this.get('errorState');
  }

  get transactionsPending (): number {
    return this.get('transactionsPending');
  }

  get amountPending (): number {
    return this.get('amountPending');
  }

  async setPendingDisbursements () {
    const response = await this.getPendingDisbursements();
    this.set('pendingDisbursements', response?.data);
    this.set('amountPending', response?.data.amountPending);
    this.set('transactionsPending', response?.data.transactionsPending);
  }

  async setMatchRequests () {
    if (!this.matchRequests) {
      const response = await this.financeResources.getMatchRequests();
      this.set('matchRequests', response.data);
    }
  }

  setPaymentIdForTransactions (paymentId: number) {
    this.set('paymentId', paymentId);
  }

  setTransactionsStatus (status: string) {
    this.set('transactionsStatus', status);
  }

  async setPaymentDetailsForTransactions (paymentId: number) {
    const response = await this.financeResources.getPaymentDetailForTransactions(paymentId);
    this.set('paymentDetailsForTransactions', response.data);
  }

  setPaymentNumber (paymentNumber: string) {
    this.set('paymentNumber', paymentNumber);
  }

  setErrorState (state: boolean) {
    this.set('errorState', state);
  }

  // APIs

  async getPayments (
    paginationOptions: PaginationOptions<PaymentDetailsApi>
  ): Promise<APIResult<PaymentDetailsUi>> {
    const paymentNumber = this.paymentNumber;
    if (!!paymentNumber) {
      paginationOptions = this.adaptPaginationOptions(
        paginationOptions,
        this.paymentNumberColumn,
        'eq',
        paymentNumber
      );
    }
    try {
      const response = await this.financeResources.getPayments(paginationOptions);
      this.setPaymentNumber(null);

      return {
        ...response,
        data: {
          ...response.data,
          records: response.data.records.map((record) => {
            return {
              ...record,
              paymentTypeId: record.paymentType?.id
            };
          })
        }
      };
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getPaymentsDownload (
    paginationOptions: PaginationOptions<PaymentDetailsApi>,
    format: TableDataDownloadFormat
  ) {
    try {
      const paymentNumber = this.paymentNumber;
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Payments-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;
      if (!!paymentNumber) {
        paginationOptions = this.adaptPaginationOptions(
          paginationOptions,
          this.paymentNumberColumn,
          'eq',
          paymentNumber
        );
      }

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.financeResources.paymentsDownloadEndpoint,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  getAllTransactions (
    paginationOptions: PaginationOptions<Transaction>
  ) {
    let isPending = false;
    const pendingStatus = TransactionStatus.Pending;

    // set the isPending flag to TRUE
    // if user selected Pending status filter
    if (paginationOptions.filterColumns.some((c) => c.columnName === this.disbursementStatusColumn)) {
      isPending = !!(paginationOptions.filterColumns.filter((c) => c.filters[0].filterValue === pendingStatus).length);

    // Or if navigating from My Workspace and Pending status was set on state
    } else if (this.transactionsStatus === pendingStatus) {
      isPending = true;
      paginationOptions = this.adaptPaginationOptions(
        paginationOptions,
        this.disbursementStatusColumn,
        'eq',
        pendingStatus
      );
    }
    const response = this.financeResources.getAllTransactions(
      paginationOptions,
      isPending
    );
    this.setTransactionsStatus('');

    return response;
  }

  async getAllTransactionsDownload (
    paginationOptions: PaginationOptions<PaymentDetailsApi>,
    format: TableDataDownloadFormat
  ) {
    try {
      let isPending = false;
      const pendingStatus = TransactionStatus.Pending;

      // set the isPending flag to TRUE
      // If user selected Pending status filter
      if (paginationOptions.filterColumns.some((c) => c.columnName === this.disbursementStatusColumn)) {
        isPending = !!(paginationOptions.filterColumns.filter((c) => c.filters[0].filterValue === pendingStatus).length);

      // Or if navigating from My Workspace and Pending status was set on state
      } else if (this.transactionsStatus === pendingStatus) {
        isPending = true;
        paginationOptions = this.adaptPaginationOptions(
          paginationOptions,
          this.disbursementStatusColumn,
          'eq',
          pendingStatus
        );
      }

      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Transactions-Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.financeResources.transactionsDownloadEndpoint,
        {
          paginationOptions,
          isPending,
          exportReportType: format
        }
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async downloadTransactionsForMultiplePayments (
    payload: MultiplePaymentsPayload,
    format: TableDataDownloadFormat
  ) {
    try {
      payload = {
        ...payload,
        exportReportType: format
      };
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Transactions-for-Multiple-Payments-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.financeResources.paymentTransactionsDownloadEndpoint,
        payload
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  /**
   *
   * @param nonprofitPaymentId paymentId that is set when user navigates from the Payments page
   * @param options paginationOptions
   * @param format exportReportType XLSX or CSV
   * @returns download model
   */

  async downloadTransactions (
    nonprofitPaymentId: number,
    options: PaginationOptions<Transaction>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Transactions-for-Payment-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.financeResources.transactionsDownloadEndpoint,
        {
          paginationOptions: options,
          nonprofitPaymentId,
          exportReportType: format
        }
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getPaginatedTransactions (
    paginationOptions: PaginationOptions<Transaction>
  ) {
    const paymentId = this.paymentId;
    const response = await this.financeResources.getPaginatedTransactions(
      paginationOptions,
      paymentId
    );

    return response;
  }

  async getReissueIneligibleReasons (
    paymentId: number
  ) {
    const response = await this.financeResources.getReissueIneligibleReasons(paymentId);

    return response;
  }

  adaptPaginationOptions (
    paginationOptions: PaginationOptions<any>,
    columnName: string,
    filterType: string,
    filterValue: any
  ) {
    return {
      ...paginationOptions,
      filterColumns: [
        ...paginationOptions.filterColumns,
        {
          columnName,
          filters: [
            {
              filterType,
              filterValue
            }
          ]
        }
      ]
    };
  }

  async getPendingDisbursements () {
    try {
      const response = await this.financeResources.getPendingDisbursements();
      this.setErrorState(false);

      return response;
    } catch (e) {
      console.error(e);
      this.setErrorState(true);

      return null;
    }
  }
}
