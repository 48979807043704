
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoginError, ResetPasswordCase } from '@core/models/auth.model';
import { AuthResources } from '@core/resources/auth.resources';
import { BbidFailedReason, BbidLinkingResult, DetermineSignInFlow, LinkToBbidPayload, LoginScenario } from '@core/typings/bbid.typing';
import { environment } from '@environment';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { NonprofitService } from '../../nonprofit/nonprofit.service';
import { AuthState } from '../state/auth.state';
import { AccountService } from './account.service';
import { BBIDService } from './bbid.service';
import { DeepLinkingService } from './deep-linking.service';
import { TokenService } from './token/token.service';

@AttachYCState(AuthState)
@Injectable({ providedIn: 'root' })
export class AuthService extends BaseYCService<AuthState> {
  constructor (
    private authResources: AuthResources,
    private tokenService: TokenService,
    private notifier: NotifierService,
    private i18n: I18nService,
    private router: Router,
    private deepLinkingService: DeepLinkingService,
    private accountService: AccountService,
    private nonprofitService: NonprofitService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private bbidService: BBIDService,
    private logger: LogService
  ) {
    super();

    // if we find a JWT in localstorage
    if (this.tokenService.tokenInfo) {
      // scenario one, both tokens are out of date
      const currentTokenValid = this.tokenService.hasCurrentValidToken();
      const futureTokenValid = this.tokenService.hasFutureValidToken();
      if (!currentTokenValid && !futureTokenValid) {
        this.tokenService.logout();
      // scenario two, jwt is old but the refresh token is valid
      } else if (!currentTokenValid && futureTokenValid && !this.tokenService.isBbid) {
        this.tokenService.doRefresh();
      }
    // scenario 3, we didn't find anything in localstorage, so the user is not logged in
    } else if (!location.pathname.includes('login') && (location.pathname !== '/')) {
      // do not hijack route if we are viewing public nonprofits
      if (location.pathname.startsWith('/nonprofits')) {
        return;
      } else if (location.pathname.startsWith('/grantterms')) {
        return;
      } else if (location.pathname.startsWith('/outreach')) {
        return;
      } else if (location.pathname.startsWith('/contact-outreach')) {
        return;
      } else if (location.pathname.includes('/admin')) {
        return;
      } else if (location.pathname.startsWith('/support-request')) {
        return;
      } else {
        this.deepLinkingService.setAttemptedRoute();
      }
    }
  }

  get loginScenario () {
    return this.get('loginScenario');
  }

  get signInFlow () {
    return this.get('signInFlow');
  }

  setLoginScenario (scenario: LoginScenario) {
    this.set('loginScenario', scenario);
  }

  setSignInFlow (flow: DetermineSignInFlow) {
    this.set('signInFlow', flow);
  }

  /**
   * Handles Initial Login By Determing the Scenario
   *
   * @param email: Email to determine sign in flow
   * @returns the login scenario
   */
  async doInitialLogin (email = this.email) {
    let scenario: LoginScenario = LoginScenario.NEW_BBID_USER;

    let response: DetermineSignInFlow = {
      hasExistingBlackbaudIdAccount: false,
      isBlackbaudIdLinked: false,
      existing: false,
      requireSso: false
    };

    response = await this.authResources.getDetermineSignInFlow(email);

    if (!!response) {
      this.setSignInFlow(response);

        // If BBID is already linked or the user has an existing BBID account
        if (response.isBlackbaudIdLinked || response.hasExistingBlackbaudIdAccount) {
          scenario = LoginScenario.EXISTING_BBID_USER;
        } else {
          // If BBID is enabled but not linked and they do not have a BBID account
          scenario = LoginScenario.NEW_BBID_USER;
        }

      this.setLoginScenario(scenario);
    } else {
      this.setSignInFlow(response);

      this.setLoginScenario(scenario);
    }

    return scenario;
  }

  /**
   * Handle linking BBID account to user
   *
   * @param inSystem: Is the user already in the system?
   * @param linked: Is the user already linked to Blackbaud Id in the system?
   * @param loginAfterLinking: Should login happen after linking?
   * @param adminInviteEmail: Email used for the admin invite if one used for sign up
   * @returns the linking result and the fail reason
   */
  async handleLinkingBbidAccount (
    inSystem: boolean,
    needToLink: boolean,
    loginAfterLinking: boolean = true,
    adminInviteEmail: string
  ): Promise<{
    linkingResult: BbidLinkingResult;
    failReason: BbidFailedReason;
  }> {
    const blackbaudIdToken = await this.bbidService.getToken();
    const parsedBlackbaudIdToken = this.tokenService.parseJwt(blackbaudIdToken);

    let proceed = true;

    if (!inSystem) {
      proceed = await this.createNPOAccountForBbid(
        parsedBlackbaudIdToken.given_name,
        parsedBlackbaudIdToken.family_name,
        parsedBlackbaudIdToken.email,
        this.i18n.language
      );
    }

    if (proceed) {
      let linkFailReason: BbidFailedReason = null;
      let linked = true;
      if (needToLink) {
        const {
          passed,
          failReason
        } = await this.linkToBbidAccount(parsedBlackbaudIdToken.email, adminInviteEmail);
        linked = passed;
        linkFailReason = failReason;
      }

      if (linked) {
        if (loginAfterLinking) {
          const passed = await this.loginWithBbid(
            true,
            true,
            false,
            null
          );
          if (passed) {
            return {
              linkingResult: BbidLinkingResult.LINKED,
              failReason: null
            };
          } else {
            return {
              linkingResult: BbidLinkingResult.FAILED,
              failReason: BbidFailedReason.BBID_LOGIN_FAILED
            };
          }
        } else {
          return {
            linkingResult: BbidLinkingResult.LINKED,
            failReason: null
          };
        }
      } else {
        return {
          linkingResult: BbidLinkingResult.FAILED,
          failReason: linkFailReason || BbidFailedReason.LINK_FAILED_UNKNOWN
        };
      }
    } else {
      return {
        linkingResult: BbidLinkingResult.FAILED,
        failReason: BbidFailedReason.ACCOUNT_CREATE_FAILED
      };
    }
  }

  /**
   * Create user account for Blackbaud Id user who uses different email to sign up on Blackbaud Id than invited with
   *
   * @param firstName: User first name
   * @param lastName: User last name
   * @param email: User email
   * @param languageCulture: User language
   * @returns if the account creation passed
   */
  async createNPOAccountForBbid (
    firstName: string,
    lastName: string,
    email: string,
    languageCulture: string
  ) {
    try {
      await this.authResources.createNPOAccountForBbid(
        firstName,
        lastName,
        email,
        languageCulture
      );

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  /**
   * Handles logic needed for new users coming to the system from Blackbaud
   *
   * @param adminInviteEmail: Email used for the admin invite if one used for sign up
   */
  async handleNewUserSlatedForBbid (
    adminInviteEmail: string
  ) {
    try {
      return await this.handleBbidForNewUserNotInSystem(adminInviteEmail);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'AUTH:textErrorSettingUpAccount',
        {},
        'There was an error setting up your account'
      ));
      this.router.navigate(['login']);
    }

    return {
      linkingResult: BbidLinkingResult.FAILED,
      failReason: BbidFailedReason.LINK_FAILED_UNKNOWN
    };
  }

  /**
   * This logic only applies for sign ups.
   *
   * @param adminInviteEmail: Email used for the admin invite if one used for sign up
   */
  async handleBbidForNewUserNotInSystem (
    adminInviteEmail: string
  ) {
    const blackbaudIdToken = await this.bbidService.getToken();
    const parsedBlackbaudIdToken = this.tokenService.parseJwt(blackbaudIdToken);

    let proceed = await this.createNPOAccountForBbid(
      parsedBlackbaudIdToken.given_name,
      parsedBlackbaudIdToken.family_name,
      parsedBlackbaudIdToken.email,
      this.i18n.language
    );
    let linkFailReason: BbidFailedReason = null;
    if (proceed) {
      const {
        passed,
        failReason
      } = await this.linkToBbidAccount(parsedBlackbaudIdToken.email, adminInviteEmail);
      proceed = passed;
      linkFailReason = failReason;
    } else {
      return {
        linkingResult: BbidLinkingResult.FAILED,
        failReason: BbidFailedReason.ACCOUNT_CREATE_FAILED
      };
    }
    if (proceed) {
      return {
        linkingResult: BbidLinkingResult.LINKED,
        failReason: null
      };
    } else {
      return {
        linkingResult: BbidLinkingResult.FAILED,
        failReason: linkFailReason || BbidFailedReason.LINK_FAILED_UNKNOWN
      };
    }
  }

  /**
   * Link NPOc account to Blackbaud Id (BBID) Account
   *
   * @param npocEmail: NPOc email
   * @param adminInviteEmail: Email sent within link sent via admin invite
   * @returns if the link was successful and failure reason if it fails
   */
  async linkToBbidAccount (
    npocEmail: string,
    adminInviteEmail: string
  ): Promise<{
    passed: boolean;
    failReason: BbidFailedReason;
  }> {
    try {
      const payload: LinkToBbidPayload = {
        email: npocEmail,
        blackbaudIdToken: await this.bbidService.getToken(),
        inviteEmail: adminInviteEmail
      };

      await this.authResources.linkToBbidAccount(payload);

      return {
        passed: true,
        failReason: null
      };
    } catch (e) {
      this.logger.error(e);

      const error = e as HttpErrorResponse;

      if (error?.error?.message === 'Blackbaud Id is already associated with user account.') {
        return {
          passed: false,
          failReason: BbidFailedReason.BBID_LINKED_TO_ANOTHER_ACCOUNT
        };
      } else {
        this.notifier.error(this.i18n.translate(
          'AUTH:textErrorLinkingBbidAccount',
          {},
          'There was an error linking your Blackbaud ID account'
        ));
      }

      return {
        passed: false,
        failReason: BbidFailedReason.LINK_FAILED_UNKNOWN
      };
    }
  }

  /**
   * Handles Initial Login Step After Email Entered
   *
   * @returns the login scenario to proceed with
   */
  async handleInitialLogin () {
    const response = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doInitialLogin(),
      '',
      this.i18n.translate(
        'AUTH:errorLoggingIn',
        {},
        'There was an error logging in'
      )
    );
    if (response.passed) {
      return response.endpointResponse;
    } else {
      this.setLoginScenario(LoginScenario.NEW_BBID_USER);

      return LoginScenario.NEW_BBID_USER;
    }
  }

  get vettingDocumentationGuidelinesLink () {
    return 'https://nppsharedresources.blob.core.windows.net/general/BB_Vetting_Doc_Guidlines.pdf';
  }

  get email () {
    return this.get('email');
  }

  get termsOfService () {
    return this.get('termsOfService');
  }

  async setTermsOfServiceLinks () {
    const termsOfServiceResponse = await this.authResources.getLatestEffectiveTermsOfService();

    this.set('termsOfService', termsOfServiceResponse.data);
  }

  setEmail (email: string) {
    this.set('email', email);
  }

  /**
   * determine "Default" route based on the users role/number admin nonprofits
   */
  async getDefaultRoute () : Promise<string> {
    const loggedIn = this.tokenService.getIsLoggedIn();
    if (!loggedIn) {
      return '/login';
    }
    if (!this.tokenService.tokenInfo) {
      await this.tokenService.fetchUserClaims();
    }
    const tokenInfo = this.tokenService.tokenInfo;
    const nonprofitCount = (tokenInfo.nonprofitRoleMap?.NonprofitAdmin?.length || 0) +
      (tokenInfo.nonprofitRoleMap?.nonprofitManager?.length || 0);
    let defaultRoute = '/my-nonprofits';

    if (
      this.isYcAdminOrSupport() ||
      this.isYCReporting()
    ) {
      defaultRoute = '/search';
    } else if (this.isImplementationManager()) {
      defaultRoute = '/implementation/switchboard';
    } else if (this.isComplianceAdmin()) {
      defaultRoute = '/compliance-admin/switchboard';
    } else if (nonprofitCount === 1) {
      defaultRoute = '/my-workspace';
    }

    return defaultRoute;
  }

  getNpoRole (): string[] {
    const activeNpo = this.nonprofitService.selectedNpo;
    const nonprofitRoleMap = this.tokenService.tokenInfo?.nonprofitRoleMap || {};

    const roles = [];
    for (const role in nonprofitRoleMap) {
      if (nonprofitRoleMap[role].includes(activeNpo)) {
        roles.push(role);
      }
    }

    return roles;
  }

  hasNpoRole (roleName: string): boolean {
    return this.getNpoRole().includes(roleName);
  }

  isNonprofitUser (): boolean {
    return this.hasNpoRole('NonprofitAdmin') ||
      this.hasNpoRole('nonprofitManager');
  }

  getSystemRole (): string[] {
    return this.tokenService.tokenInfo?.role || [];
  }

  hasSystemRole (roleName: string): boolean {
    return this.getSystemRole().includes(roleName);
  }

  isYcAdmin (): boolean {
    return this.getSystemRole().includes('YcAdmin');
  }

  isYcSupport (): boolean {
    return this.getSystemRole().includes('YcSupport');
  }

  isSiteAdmin (): boolean {
    return this.getSystemRole().includes('SiteAdmin');
  }

  isYcNonprofitManager (): boolean {
    return this.getSystemRole().includes('YcNonprofitManager');
  }

  isComplianceAdmin (): boolean {
    return this.getSystemRole().includes('ComplianceAdmin');
  }

  isImplementationManager (): boolean {
    return this.getSystemRole().includes('ImplementationManager');
  }

  isYcAdminOrSupport (): boolean {
    return this.isYcAdmin() || this.isYcSupport();
  }

  isInternalUser (): boolean {
    return this.accountService.accountDetails?.isInternalUser;
  }

  isCheckReissue (): boolean {
    return this.getSystemRole().includes('CheckReissue');
  }

  isYCNonprofitACHManager (): boolean {
    return this.getSystemRole().includes('YCNonprofitACHManager');
  }

  isYCReporting (): boolean {
    return this.getSystemRole().includes('YcReporting');
  }

  isEngineering (): boolean {
    return this.getSystemRole().includes('Engineering');
  }

  isInternalAdmin () {
    return this.isComplianceAdmin() ||
      this.isImplementationManager() ||
      this.isSiteAdmin() ||
      this.isYcAdminOrSupport() ||
      this.isYcNonprofitManager() ||
      this.isEngineering();
  }

  canImpersonateNonprofit () {
    return this.isSiteAdmin() ||
    this.isYcAdminOrSupport() ||
    this.isYcNonprofitManager();
  }

  hasActiveNonprofit (): boolean {
    return !!this.nonprofitService.activeNpo;
  }

  isAdminOfNonprofit (id: number) {
    const nonprofitIdAttr = this.tokenService.tokenInfo?.NonprofitIdArr;
    if (nonprofitIdAttr) {
      return nonprofitIdAttr.includes(+id);
    }

    return false;
  }

  handleError (err: HttpErrorResponse) {
    const allErrorCodes = Object.keys(LoginError);
    const errorCode = err?.error?.errorCode;
    const unknownErrorText = this.i18n.translate(
      'common:notificationAnUnknownErrorOccurred',
      {},
      'An unknown error occurred'
    );

    if (errorCode) {
      if ([
          LoginError.Email_Not_Confirmed,
          LoginError.Token_Is_Not_Valid
        ].includes(errorCode)
      ) {
        this.notifier.error(this.i18n.translate(
          'login:notificationEmailNotConfirmed',
          {},
          'This account has not yet been verified. Please enter your address below to start the account verification process.'
        ));
        this.router.navigateByUrl('/login/pending-confirm-email');
      } else if (errorCode === LoginError.Account_Not_Valid) {
        this.notifier.error(this.i18n.translate(
          'login:notificationPasswordOrEmailIncorrect',
          {},
          'Your email or password was incorrect'
        ));
      } else if (errorCode === LoginError.Account_Requires_SSO) {
        this.notifier.error(this.i18n.translate(
          'login:notificationGoToSSO',
          {},
          'Please login using SSO'
        ));
      } else if (errorCode === LoginError.Password_Expired) {
        this.notifier.error(this.i18n.translate(
          'login:notificationPasswordExpired',
          {},
          'Your password has expired'
        ));
        this.router.navigate(['/login/forgot-password', ResetPasswordCase.EXPIRED]);
      } else if (errorCode === LoginError.Security_Code_Required) {
        this.router.navigate(['/login/verification-code']);
      } else if (!allErrorCodes.includes(errorCode)) {
        this.notifier.error(unknownErrorText);
      }
    } else {
      this.notifier.error(unknownErrorText);
    }

    return errorCode || LoginError.Unknown;
  }

  getBlackbaudLoginRoute () {
    return this.authResources.getBlackbaudLoginRoute();
  }

  handleResetPasswordError (err: HttpErrorResponse|Error) {
    console.error(err);
    if (err.message === 'Token is not valid') {
      this.notifier.error(
        this.i18n.translate(
          'common:notificationLinkNoLongerValid',
          {},
          'Your Password Reset email has expired.  Please submit a new Password Reset request to receive a new email.'
        )
      );
      this.router.navigate(['/login/forgot-password', ResetPasswordCase.REQUEST_RESET]);
    } else {
      this.notifier.error(
        err.message ?
        this.i18n.translate(`login:${err.message}`) :
        this.i18n.translate(
          'common:notificationFailedToResetYourPassword',
          {},
          'Failed to reset your password'
        )
      );
    }
  }

  async getUserTermsOfService () {
    return await this.authResources.getUserTermsOfService();
  }

  async acknowledgeTermsOfService (termsOfServiceId: number) {
    await this.authResources.acknowledgeTermsOfService(termsOfServiceId);
    await this.tokenService.doRefresh(true);
  }

  /**
   * Check if the user logged in with a valid BBID for the NPO account
   *
   * @returns true if the BBID the  user selected is valid and they can proceed
   */
  async verifyBbidLink () {
    try {
      await this.authResources.verifyBbidLink();

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  /**
   * Login with BBID
   *
   * @param attemptDeepLink: Attempt to deep link after login
   * @param logoutOnFail: Logout if it fails?
   * @param isInitialSignin: is this the initial sign in? (not refresh of page)
   * @param claimedNonprofitId: is the optional id of the claimed nonprofit when a user signs up
   * @returns if the BBID login was successful or not
   */
  async loginWithBbid (
    attemptDeepLink: boolean,
    logoutOnFail: boolean,
    npoUserFirstSignIn: boolean,
    claimedNonprofitId?: number
  ) {
    try {
      await this.tokenService.setBbidJwt(false);
      const token = this.tokenService.parseJwt();
      const isValidBbid = await this.verifyBbidLink();

      if (isValidBbid) {
        await this.bbidService.loadOmnibar();
        this.authResources.trackBbidLogin();

        if (npoUserFirstSignIn) {
          this.accountService.claimANonprofit(claimedNonprofitId);
        }

        await this.tokenService.fetchUserClaims();

        if (attemptDeepLink) {
          var route = await this.getDefaultRoute();
          this.deepLinkingService.attemptDeepLink(route, { alreadyLoggedInWithBbid: true });
        }

        return true;
      } else {
        this.logger.log('BBID login failed with invalid from endpoint - VerifyBbidLink', {
          email: token?.email
        });

        if (logoutOnFail) {
          this.tokenService.logout();
        }

        return false;
      }
    } catch (e) {
      this.logger.error(e);

      const token = this.tokenService.parseJwt();

      this.logger.log('BBID login failed from unknown', {
        email: token?.email
      });

      if (logoutOnFail) {
        this.tokenService.logout();
      }

      return false;
    }
  }

  /**
   * Sync first and last name from BBID
   *
   * @param firstName: First name
   * @param lastName: Last name
   */
  async syncInfoFromBBID (
    firstName: string,
    lastName: string
  ) {
    try {
      await this.authResources.syncInfoFromBBID(firstName, lastName);
    } catch (e) {
      this.logger.error(e);

      this.logger.log('BBID sync first and last name failed: ', {
        firstName: firstName,
        lastName: lastName
      });
    }
  }

  doRedirect (href: string) {
    location.href = href;
  }

  async handleBlackbaudSsoRedirect (route: ActivatedRouteSnapshot) {
    // if trying to get onto the platform tools from localhost
    if (environment.locationBase === 'localhost') {
      this.doRedirect(
        `https://npo.yourcausetest.com/admin/auth/signin?platformHostRename=${encodeURIComponent('localhost:51849')}`
      );

      return;
    }

    // check for redirect
    const potentialRedirect = route.queryParams.platformHostRename;
    if (potentialRedirect) {
      // remember this in order to perform redirect after SSO redirect
      sessionStorage.setItem('platformHostRename', potentialRedirect);
    }

    const response = await this.getBlackbaudLoginRoute();

    this.doRedirect(response.data.signInUrl);
  }
}
