import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { SpinnerService } from '@core/services/spinner.service';
import { I18nService } from '@yourcause/common/i18n';

/**
 * Main App Component.
 */
@Component({
  selector: 'yc-root',
  styleUrls: ['./app.component.scss'],
  template: `
  <yc-router-resolve-block></yc-router-resolve-block>
  <yc-block-ui
    [message]="loadingMessage$ | async"
    [blocking]="blocking$ | async">
  </yc-block-ui>
  <router-outlet *ngIf="langsResolved"></router-outlet>`
})
export class AppComponent implements OnInit {
  blocking$ = this.spinnerService.changesTo$('loading');
  loadingMessage$ = this.spinnerService.changesTo$('loadingMessage');
  langsResolved = false;

  constructor (
    private spinnerService: SpinnerService,
    private i18n: I18nService,
    private router: Router,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit () {
    if (location.pathname === '/') {
      this.router.navigateByUrl(await this.authService.getDefaultRoute());
    }
    this.spinnerService.startSpinner();
    this.i18n.namespaceReady()
      .then(() => {
        this.spinnerService.stopSpinner();
        this.langsResolved = true;
      });
    this.spinnerService.changesTo$('loading')
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }
}
