import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { ISupportItem, ISupportRequest } from './models';

/**
 * State for the submitting a support request.
 */
@RegisterYCState()
export class SupportRequestState extends BaseYcState {
  readonly isValid: boolean;
  readonly currentStep: number;
  readonly supportItems: ISupportItem[];
  readonly supportItem: ISupportItem;
  readonly supportRequest: ISupportRequest;
}
