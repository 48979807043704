import { Injectable } from '@angular/core';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { Observable, tap } from 'rxjs';
import { SupportRequestState } from './support-request.state';
import { ISupportItem, ISupportRequest, ISupportRequestAsset } from './models';
import { SupportRequestResources } from './support-request.resources';
import { AccountService } from '@core/services/account.service';
import { YcFile } from '@yourcause/common/files';
import { TokenService } from '@core/services/token/token.service';

/**
 * Support Request Service.
 */
@AttachYCState(SupportRequestState)
@Injectable({ providedIn: 'root' })
export class SupportRequestService extends BaseYCService<SupportRequestState> {

  constructor (
    private supportRequestResources: SupportRequestResources,
    private accountService: AccountService,
    private tokenService: TokenService
  ) {
    super();

    const defaultSupportRequest = {
      id: this.generateUUID(),
      firstName: this.accountService.accountDetails?.firstName,
      lastName: this.accountService.accountDetails?.lastName,
      email: this.tokenService.userEmail,
      phoneNumber: this.accountService.accountDetails?.phoneNumber
    } as ISupportRequest;
    this.setSupportRequest(defaultSupportRequest);
  }

  get supportItems (): ISupportItem[] {
    return this.get('supportItems');
  }

  get supportItem (): ISupportItem {
    return this.get('supportItem');
  }

  get supportRequest (): ISupportRequest {
    return this.get('supportRequest');
  }

  get isValid (): boolean {
    return this.get('isValid');
  }

  get currentStep (): number {
    return this.get('currentStep');
  }

  /**
   * Get and set support items.
   */
  setSupportItems (): Observable<ISupportItem[]> {
    return this.supportRequestResources.getSupportItems()
      .pipe(
        tap(r => this.set('supportItems', r))
      );
  }

  /**
   * Set the support request.
   */
  setSupportRequest (supportRequest: ISupportRequest): void {
    this.supportRequestResources.createSupportRequest(supportRequest)
      .subscribe(_ => this.set('supportRequest', supportRequest));
  }

  /**
   * Set the current step of the stepper.
   *
   * @param step step
   */
  setCurrentStep (step: number) {
    this.set('currentStep', step);
  }

  /**
   * Set the user selected support item.
   */
  setSupportItem (supportItem: ISupportItem) {
    const supportRequest = {...this.supportRequest, ...{supportItemId: supportItem?.id}};
    this.setSupportRequest(supportRequest);
    if (supportItem == null || supportItem.items.length > 0
      || (this.supportItem == null && supportItem.items.length === 0)) {
      this.set('supportItem', supportItem);
    }
  }

  /**
   * Set to true if the form is valid and can be submitted.
   */
  setIsValid (isValid: boolean): void {
    this.set('isValid', isValid);
  }

  /**
   * Upload and attach a file to a support request.
   */
  uploadAsset (file: YcFile): Observable<ISupportRequestAsset> {
    const supportRequestId = this.supportRequest.id;

    return this.supportRequestResources.uploadAsset(supportRequestId, file);
  }

  /**
   * Delete a file from a support request
   */
  deleteAsset (assetId: string): Observable<boolean> {
    const supportRequestId = this.supportRequest.id;

    return this.supportRequestResources.deleteAsset(supportRequestId, assetId);
  }

  /**
   * Submit the request and create a case (support ticket).
   */
  submit (): Observable<boolean> {
    // setting submit to true will trigger the item to be sent to the support team.
    const supportRequest = this.supportRequest;
    supportRequest.submit = true;

    return this.supportRequestResources.createSupportRequest(supportRequest);
  }

  generateUUID (): string {
    function _p8 (s: boolean = false) {
      const p = (Math.random().toString(16)+'000000000').substr(2,8);

      return s ? '-' + p.substr(0,4) + '-' + p.substr(4,4) : p ;
    }

    return _p8() + _p8(true) + _p8(true) + _p8();
  }
}
