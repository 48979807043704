const API_OVERRIDE = localStorage.getItem('NPO_API_OVERRIDE');

interface EnvironmentMap<T = string> {
  'localhost': T;
  'dev-nonprofitplatform-ui.blackbaud.net': T;
  'test-nonprofitplatform-ui.blackbaud.net': T;
  'qa-nonprofitplatform-ui.blackbaud.net': T;
  'npo.yourcausetest.com': T;
  'uat-nonprofitplatform-ui.blackbaud.net': T;
  'npo.yourcauseuat.com': T;
  'nonprofit.yourcause.com': T;
}

// change this variable to test against a different environment locally
const QA_FIREWALL_API_URL = 'https://qa-nonprofitplatform-api.blackbaud.net';

const apiUrls: EnvironmentMap<string> = {
  localhost: API_OVERRIDE || QA_FIREWALL_API_URL,
  'npo.yourcausetest.com': API_OVERRIDE || QA_FIREWALL_API_URL,
  'qa-nonprofitplatform-ui.blackbaud.net': API_OVERRIDE || QA_FIREWALL_API_URL,
  'npo.yourcauseuat.com': 'https://uat-nonprofitplatform-api.blackbaud.net',
  'nonprofit.yourcause.com': 'https://production-nonprofitplatformapi.blackbaud.net',
  'uat-nonprofitplatform-ui.blackbaud.net': 'https://uat-nonprofitplatform-api.blackbaud.net',
  'test-nonprofitplatform-ui.blackbaud.net': 'https://test-nonprofitplatform-api.blackbaud.net',
  'dev-nonprofitplatform-ui.blackbaud.net': 'https://dev-nonprofitplatform-api.blackbaud.net'
};

const appInsightsKeys: EnvironmentMap<string> = {
  localhost: null,
  'dev-nonprofitplatform-ui.blackbaud.net': '7afcf07d-4903-4345-b537-05514fe6eff3',
  'test-nonprofitplatform-ui.blackbaud.net': '4ae2d180-dce7-4b83-814d-6a68c0ebddec',
  'qa-nonprofitplatform-ui.blackbaud.net': '42249e31-5195-4e44-88dd-fb60c16664e5',
  'npo.yourcausetest.com': '42249e31-5195-4e44-88dd-fb60c16664e5',
  'uat-nonprofitplatform-ui.blackbaud.net': '56119b27-57b0-44f7-a097-908ca59aab7a',
  'npo.yourcauseuat.com': '56119b27-57b0-44f7-a097-908ca59aab7a',
  'nonprofit.yourcause.com': 'fc685c53-71a6-4185-ad02-201476b4aba1'
};
let locationBase = location.hostname as keyof EnvironmentMap;

const isTest = navigator.userAgent.includes('Node.js') ||
  navigator.userAgent.includes('jsdom');

if (isTest) {
  locationBase = 'localhost';
}

const version = require('./version.json');
export const environment = {
  production: (locationBase !== 'localhost') && !isTest,
  hmr: false,
  apiBase: apiUrls[locationBase],
  version,
  locationBase,
  insightsKey: appInsightsKeys[locationBase],
  googleApiKey: 'AIzaSyCr6Ur98TNl2WQBm7wfbuG-y039INALkOY',
  get routeBase () {
    return location.pathname.split('/')[1];
  },
  get isLocalhost () {
    return locationBase === 'localhost';
  },
  get addGoogleTagManagerProd () {
    return locationBase === 'nonprofit.yourcause.com';
  },
  get addGoogleTagManagerNotProd () {
    return locationBase !== 'nonprofit.yourcause.com';
  },
  get addMetaFacebookPixel () {
    return locationBase === 'nonprofit.yourcause.com';
  },
  get addLinkedInInsights () {
    return locationBase !== 'nonprofit.yourcause.com';
  }
};
