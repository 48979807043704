import { Injectable } from '@angular/core';
import { PaginationOptions } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { ISupportItem, SubmittedSupportRequest, SupportRequestIssueDiagnosis } from '../../support-request/services/models';
import { SupportRequestService } from '../../support-request/services/support-request.service';
import { SubmittedSupportRequestsResources } from './submitted-support-requests.resources';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { SubmittedSupportRequestsState } from './submitted-support-requests.state';

@AttachYCState(SubmittedSupportRequestsState)
@Injectable({ providedIn: 'root' })
export class SubmittedSupportRequestsService extends BaseYCService<SubmittedSupportRequestsState> {
  errorRetrievingDataText = this.i18n.translate(
    'common:notificationErrorRetreivingData',
    {},
    'There was an error retrieving the data'
  );
  supportItems = this.supportRequestService.supportItems;

  constructor (
    private submittedSupportRequestsResources: SubmittedSupportRequestsResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private supportRequestService: SupportRequestService
  ) {
    super();
  }

  get supportRequestStatuses (): string[] {
    return this.get('supportRequestStatuses');
  }

  setSupportRequestStatusesData (data: string[]) {
    this.set('supportRequestStatuses', data);
  }

  async getSupportRequestStatuses () {
    try {
      const response = await this.submittedSupportRequestsResources.getSupportRequestStatuses();

      this.setSupportRequestStatusesData(response?.data);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @returns: array of display and value for supportItems for select list
   */
    mapSupportItems (
      supportItems: ISupportItem[]
    ): TypeaheadSelectOption[] {
      const items = supportItems?.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });

      return items;
    }


  async getSupportRequests (
    paginationOptions: PaginationOptions<SubmittedSupportRequest>
  ) {
    try {
      const response = await this.submittedSupportRequestsResources.getSupportRequests(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async createIssueDiagnosis (payload: SupportRequestIssueDiagnosis) {
    try {
      const response = await this.submittedSupportRequestsResources.createIssueDiagnosis(payload);
      this.notifier.success(this.i18n.translate(
        'supportRequest:notificationSuccessSubmittingDiagnosis',
        {},
        'Successfully submitted the issue diagnosis'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'supportRequest:notificationErrorSubmittingDiagnosis',
        {},
        'There was an error submitting the issue diagnosis'
      ));
    }
  }
}