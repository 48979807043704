import { Injectable } from '@angular/core';
import { CheckReissueDisplayModel, CompanyDisplayModel } from '@core/models/check-reissue.model';
import { NonprofitComplianceRecord } from '@core/models/compliance.model';
import { ExternalSystemTypeName } from '@core/models/payment.model';
import { ActiveNonprofitsByEFG, ActiveNpoByEFGLinkPayload, BankCustomAttributesReportReturnModel, BbiNonprofitSubsectorReportModel, BbiNonprofitSubsectorReportPayload, BbiSubsectorFromApi, ChapterReportModel, ChapterReportModelForDownload, CharityAdminDetailDownloadInfo, ComplianceAndAffirmationCSV, ComplianceRecord, CustomComplianceCompany, EmailLogReportModel, FailedPaymentReturnedChecksReportDisplayReturnModel, ImportSummaryModel, InactiveNpoWithPendingDisbursementModel, JobsReportModel, MergedNonprofitsReportModel, NonprofitClassificationHierarchyRequestModel, NpoOnExclusionList, NpoWithDisbursementSuspended, NpoWithDisbursementSuspendedDownload, OutstandingPaymentModel, ImportHistoryModel, ReportsAndReportTypesRequestModel, TransactionDetail, VR_REPORT_STATUS_OPTION, VettingApplicationsSubmittedOrCompletedReportDisplayModel, VettingRequestForReportingModel, NonprofitEligibileForGivingOverridesDto, RevokedNonprofitDto } from '@core/models/reporting.model';
import { VettingExpirationReport } from '@core/models/vetting.model';
import { ExtractFilterColumnsService } from '@core/services/extract-filter-columns.service';
import { SystemEmailsAPI } from '@core/typings/api/system-email.typing';
import { FilterColumn, PaginationOptions, FilterModalTypes, TopLevelFilter, FilterHelpersService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ReportingResources } from './reporting.resources';
import { ReportingState } from '../reporting.state';
import { VettingRequestStatusId } from '@core/models/status.model';
import { AssetManagementService } from '../../asset-management/services/asset-management.service';
import { DateService, TIMESTAMP_FORMAT } from '@yourcause/common/date';
import { FileService, TableDataDownloadFormat } from '@yourcause/common/files';
import { IsNumber, Required } from '@yourcause/common/form-control-validation';

@AttachYCState(ReportingState)
@Injectable({ providedIn: 'root' })
export class ReportingService extends BaseYCService<ReportingState> {
  readonly companyIdColumn = 'companyId';
  readonly startDateColumn = 'startDate';
  readonly checkReissueStatusIdColumn = 'checkReissueStatusId';
  readonly searchTypeColumn = 'searchType';
  readonly paymentRangeFilter = 'paymentRange';
  efgReportPaginationOptions: PaginationOptions<JobsReportModel>;

  errorRetrievingDataText = this.i18n.translate(
    'common:notificationErrorRetreivingData',
    {},
    'There was an error retrieving the data'
  );

  constructor (
    private reportingResources: ReportingResources,
    private extractColumnsService: ExtractFilterColumnsService,
    private i18n: I18nService,
    private dateService: DateService,
    private notifier: NotifierService,
    private fileService: FileService,
    private filterHelperService: FilterHelpersService,
    private assetManagementService: AssetManagementService
  ) {
    super();
  }

  get charityAdminDetailDownloadInfo (): CharityAdminDetailDownloadInfo {
    return this.get('charityAdminDetailDownloadInfo');
  }

  get customComplianceCompanies (): CustomComplianceCompany[] {
    return this.get('customComplianceCompanies');
  }

  get allCompliances (): NonprofitComplianceRecord[] {
    return this.get('allCompliances');
  }

  get outstandingPaymentId (): number {
    return this.get('outstandingPaymentId');
  }

  get outstandingPaymentNumber (): number {
    return this.get('outstandingPaymentNumber');
  }

  get paymentNonprofitId (): number {
    return this.get('paymentNonprofitId');
  }

  get paymentNonprofitName (): string {
    return this.get('paymentNonprofitName');
  }

  get drilldownRowId (): number {
    return this.get('drilldownRowId');
  }

  get currentEfgReportDateTime () {
    return this.get('currentEfgReportDateTime');
  }

  get emailClassesForFilter () {
    return this.get('emailClassesForFilter');
  }

  get emailClasses () {
    return this.get('emailClasses');
  }

  get allCompanies (): CompanyDisplayModel[] {
    return this.get('allCompanies');
  }

  get companiesForInput (): CompanyDisplayModel[] {
    return this.get('companiesForInput');
  }

  get bbiSubsectorData (): BbiSubsectorFromApi[] {
    return this.get('bbiSubsectorData');
  }

  get revokedNonprofitsSubdivisions (): string[] {
    return this.get('revokedNonprofitsSubdivisions');
  }

  get adminEmail (): string {
    return this.get('adminEmail');
  }

  get ycProcessingPartnerNames (): string[] {
    return this.get('ycProcessingPartnerNames');
  }

  getRelativeDateFilters (
    dateRangeValue = FilterModalTypes.Last30Days
  ) {
    return new TopLevelFilter(
      'relativeDate',
      'applicationSubmittedDate',
      dateRangeValue,
      '',
      this.filterHelperService.getRelativeDateFilterConfig(),
      this.i18n.translate(
        'common:textFilterByDate',
        {},
        'Filter by date'
      )
    );
  }

  setDrilldownRowId (id: number) {
    this.set('drilldownRowId', id);
  }

  setOutstandingPaymentInfo (
    paymentId: number,
    paymentNumber: number,
    nonprofitId: number,
    nonprofitName: string
  ) {
    this.set('outstandingPaymentId', paymentId);
    this.set('outstandingPaymentNumber', paymentNumber);
    this.set('paymentNonprofitId', nonprofitId);
    this.set('paymentNonprofitName', nonprofitName);
  }

  async setCharityAdminReportLink () {
    const url = await this.reportingResources.getReportingLink();
    this.set('charityAdminDetailDownloadInfo', {
      url,
      fileName: 'Charity-Admin-Detail-Report.csv'
    });
  }

  async setCustomComplianceCompanies () {
    const response = await this.reportingResources.getCustomComplianceCompanies();
    this.set('customComplianceCompanies', response);
  }

  setAllCompliances (compliances: NonprofitComplianceRecord[]) {
    this.set('allCompliances', compliances);
  }

  async setCurrentEfgReportDateTime () {
    const response = await this.getCurrentEfgReportDateTime();
    let dateString: string;
    if (response) {
      dateString = this.dateService.formatDate(response);
    } else {
      // Job: PopulateEFGReportTables runs daily @ 9:00AM UTC.
      // Adding an approx default date/time in the event the data
      // doesn't come back from the GetJobReport>lastExecutedDate
      dateString = this.dateService.formatDate(new Date()) + ` 5:00 AM`;
    }
    this.set('currentEfgReportDateTime', dateString);
  }

  setEmailClassesForFilter (classes: SystemEmailsAPI.EmailClass[]) {
    const classesForFilter = classes.map((c) => {
      return {
        id: c.id,
        name: c.name
      };
    });
    this.set('emailClassesForFilter', classesForFilter);
  }

  setEmailClasses (classes: SystemEmailsAPI.EmailClass[]) {
    this.set('emailClasses', classes);
  }

  setAllCompanies (companies: CompanyDisplayModel[]) {
    this.set('allCompanies', companies);
  }

  setCompaniesForInput (companies: CompanyDisplayModel[]) {
    const filteredCompanies = companies.filter((company) => {
      return company.externalSystemType !== ExternalSystemTypeName.GDG;
    });
    this.set('companiesForInput', filteredCompanies);
  }

  setBbiSubsectorData (data: BbiSubsectorFromApi[]) {
    this.set('bbiSubsectorData', data);
  }

  setRevokedNonprofitsSubdivisionsData (data: string[]) {
    this.set('revokedNonprofitsSubdivisions', data);
  }

  setAdminEmail (adminEmail: string) {
    return this.set('adminEmail', adminEmail);
  }

  async setYcProcessingPartnerNames () {
    const names = await this.getYCProcessingPartnerNames();
    this.set('ycProcessingPartnerNames', names);
  }

  // ADAPTERS

  getPaginationOptionsForEmailLog (
    paginationOptions: PaginationOptions<EmailLogReportModel>,
    nonprofitId?: number
  ) {
    const adminEmail = this.adminEmail;
    let adaptedPaginationOptions: PaginationOptions<EmailLogReportModel>;
    if (adminEmail) {
      adaptedPaginationOptions = this.extractColumnsService.adaptPaginationOptions(
        paginationOptions,
        'toEmail',
        'text',
        adminEmail
      );
    } else {
      // make sure we clear the toEmail column in case it
      // previously had a value based on adminEmail val
      const toEmailCol = paginationOptions.filterColumns.find((col) => col.columnName === 'toEmail');
      toEmailCol?.filters[0]?.filterValue ? '' : undefined;

      adaptedPaginationOptions = paginationOptions;
    }
    if (nonprofitId) {
      adaptedPaginationOptions = this.extractColumnsService.adaptPaginationOptions(
        adaptedPaginationOptions,
        'nonprofitId',
        'number',
        nonprofitId
      );
    }
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        adaptedPaginationOptions,
        [this.searchTypeColumn]
      );

    return adaptedParams;
  }

  getAdaptedBbiSubsctorCodePayload (
    paginationOptions: PaginationOptions<BbiNonprofitSubsectorReportModel>
  ): BbiNonprofitSubsectorReportPayload {
    let earliestPaymentDate: any;
    let latestPaymentDate: any;
    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((option) => {
      if (
        option.columnName === this.paymentRangeFilter &&
        option.filters[0].filterType === 'ge'
      ) {
        earliestPaymentDate = option.filters[0].filterValue;

        return false;
      } else if (
        option.columnName === this.paymentRangeFilter &&
        option.filters[0].filterType === 'le'
      ) {
        latestPaymentDate = option.filters[0].filterValue;

        return false;
      } else {
        return true;
      }
    });
    const payload: BbiNonprofitSubsectorReportPayload = {
      earliestPaymentDate,
      latestPaymentDate,
      paginationOptions
    };

    return payload;
  }

  adaptChapterDownloadPayload (
    paginationOptions: PaginationOptions<ChapterReportModel>
  ) {
    const adaptedPaginationOptions: PaginationOptions<ChapterReportModelForDownload> = {} as PaginationOptions<ChapterReportModelForDownload>;
    adaptedPaginationOptions.filterColumns = [];
    adaptedPaginationOptions.returnAll = true;
    adaptedPaginationOptions.sortColumns = [{
      columnName: 'chapterName',
      sortAscending: true
    }];
    paginationOptions.filterColumns.forEach((column) => {
      if (column.columnName === 'name') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'chapterName',
          filters: [{
            filterType: 'cn',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'registrationId') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'chapterRegistrationId',
          filters: [{
            filterType: 'cn',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'parentNonprofit.name') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'parentName',
          filters: [{
            filterType: 'cn',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'parentNonprofit.registrationId') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'parentRegistrationId',
          filters: [{
            filterType: 'cn',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'parentNonprofit.registrationAuthorityName') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'parentRegistrationAuthority',
          filters: [{
            filterType: 'cn',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'registrationAuthorityName') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'chapterRegistrationAuthority',
          filters: [{
            filterType: 'cn',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'createDate') {
        const dateFilterType = column.filters[0].filterType;
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'chapterCreateDate',
          filters: [{
            filterType: dateFilterType,
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'parentNonprofit.createDate') {
        const dateFilterType = column.filters[0].filterType;
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'parentCreateDate',
          filters: [{
            filterType: dateFilterType,
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else if (column.columnName === 'parentNonprofit.hasAchInfo') {
        adaptedPaginationOptions.filterColumns.push({
          columnName: 'parentHasACH',
          filters: [{
            filterType: 'eq',
            filterValue: column.filters[0].filterValue
          }]
        });

        return false;
      } else {
        return true;
      }
    });

    return adaptedPaginationOptions;
  }

  // APIs

  async getReportsAndReportTypes (
    paginationOptions: PaginationOptions<ReportsAndReportTypesRequestModel>
  ) {
    try {
      const response = await this.reportingResources.getReportsAndReportTypes(
        paginationOptions
      );

      return response;
    } catch (e) {
      this.notifier.error(this.errorRetrievingDataText);
      throw e;
    }
  }

  async getChapterReport (
    paginationOptions: PaginationOptions<ChapterReportModel>
  ) {
    try {
      const response = await this.reportingResources.getChapterReport(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadChapterReport (
    paginationOptions: PaginationOptions<ChapterReportModel>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Nonprofit_Chapters-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      const adaptedOptions = this.adaptChapterDownloadPayload(paginationOptions);

      return this.assetManagementService.downloadCsvOrExcel(
        adaptedOptions,
        format,
        fileName,
        this.reportingResources.downloadNonprofitChaptersReportEndpoint,
        null
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getEmailTemplateReport (
    paginationOptions: PaginationOptions<SystemEmailsAPI.Email>
  ) {
    const subjectTriggerFilter = 'subjectTriggerFilter';
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [subjectTriggerFilter]
      );
    try {
      const response = await this.reportingResources.getEmailTemplateReport(
        adaptedParams.paginationOptions,
        adaptedParams.params[subjectTriggerFilter]
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadEmailTemplateReport (
    paginationOptions: PaginationOptions<SystemEmailsAPI.Email>,
    format: TableDataDownloadFormat
  ) {
    const subjectTriggerFilter = 'subjectTriggerFilter';
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [subjectTriggerFilter]
      );
    const payload = {
      paginationOptions: adaptedParams.paginationOptions,
      subjectTriggerFilter: adaptedParams.params[subjectTriggerFilter]
    };

    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Email_Templates_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.emailTemplatesForDownloadEndpoint,
        payload
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }

  }

  async getEmailClasses () {
    const response = await this.reportingResources.getEmailClasses();
    this.setEmailClasses(response);
    this.setEmailClassesForFilter(response);
  }

  async getOutstandingPaymentsReport (
    paginationOptions: PaginationOptions<OutstandingPaymentModel>
  ) {
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [
          this.companyIdColumn,
          this.startDateColumn
        ]
      );

    try {
      const response = await this.reportingResources.getOutstandingPaymentsReport(
        adaptedParams.paginationOptions,
        adaptedParams.params[this.companyIdColumn],
        adaptedParams.params[this.startDateColumn]
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadOutstandingPayments (
    paginationOptions: PaginationOptions<OutstandingPaymentModel>,
    format: TableDataDownloadFormat
  ) {
    try {
      const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [
          this.companyIdColumn,
          this.startDateColumn
        ]
      );
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Outstanding_Payments-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;
      const payload = {
        paginationOptions: adaptedParams.paginationOptions,
        companyId: adaptedParams.params[this.companyIdColumn],
        startDate: adaptedParams.params[this.startDateColumn],
        exportReportType: format
      };

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.outstandingPaymentsWithDonationsDownload,
        payload
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getOutstandingTransactions (
    paginationOptions: PaginationOptions<TransactionDetail>
  ) {
    try {
      const response = await this.reportingResources.getOutstandingTransactions(
        paginationOptions,
        this.outstandingPaymentId
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadOutstandingTransactions (
    paginationOptions: PaginationOptions<TransactionDetail>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Outstanding_Transactions-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.outstandingPaymentTransactionsDownload,
        {
          paginationOptions,
          nonprofitPaymentId: this.outstandingPaymentId,
          exportReportType: format
        }
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getInactiveNposWithPendingDisbursements (
    paginationOptions: PaginationOptions<InactiveNpoWithPendingDisbursementModel>
  ) {
    try {
      const response = await this.reportingResources.getInactiveNposWithPendingDisbursements(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getInactiveNposWithPendingDisbursementsDownload (
    paginationOptions: PaginationOptions<InactiveNpoWithPendingDisbursementModel>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Inactive_Npos_With_Pending_Disbursements_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.inactiveNPOsWithPendingDonationsDownloadEndpoint,
        null
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getMergedNonprofitsReport (
    paginationOptions: PaginationOptions<MergedNonprofitsReportModel>
  ) {
    try {
      const response = await this.reportingResources.getMergedNonprofitsReport(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getMergedNonprofitsReportDownload (
    paginationOptions: PaginationOptions<MergedNonprofitsReportModel>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Merged_Nonprofits_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.nonprofitMergeReportDownload,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getNonprofitExclusionList (
    paginationOptions: PaginationOptions<NpoOnExclusionList>
  ) {
    try {
      const response = await this.reportingResources.getNonprofitExclusionList(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadNonprofitExclusionList (
    options: PaginationOptions<NpoOnExclusionList>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Nonprofit_Exclusion_List-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        options,
        format,
        fileName,
        this.reportingResources.downloadNonprofitExclusionListEndpoint,
        null
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  getComplianceAndAffiliationsReport (
    paginationOptions: PaginationOptions<ComplianceRecord>
  ) {
    if (paginationOptions.filterColumns.length) {
      const adaptedParams = this.extractColumnsService
        .extractFilterColumnsFromPaginationOptions(
          paginationOptions,
          [this.companyIdColumn]
        );

      const filteredPaginationOptions = {
        ...adaptedParams.paginationOptions
      };

      return this.reportingResources.getComplianceAndAffiliationsReport(
        filteredPaginationOptions,
        adaptedParams.params[this.companyIdColumn]
      );
    } else {
      return this.reportingResources.getComplianceAndAffiliationsReport(
        paginationOptions,
        null
      );
    }

  }

  async getComplianceAndAffiliationsDownload (
    paginationOptions: PaginationOptions<ComplianceRecord>,
    format: TableDataDownloadFormat
  ) {
    try {
      let filteredPaginationOptions: PaginationOptions<ComplianceRecord>;
      let companyId: number;
      let companyName: string;

      if (paginationOptions.filterColumns?.length > 0) {
        const adaptedParams = this.extractColumnsService
          .extractFilterColumnsFromPaginationOptions(
            paginationOptions,
            [this.companyIdColumn]
          );
        companyId = adaptedParams.params[this.companyIdColumn];
        filteredPaginationOptions = {
          ...adaptedParams.paginationOptions,
          returnAll: true
        };
      } else {
        companyId = null;
        filteredPaginationOptions = {
          ...paginationOptions,
          returnAll: true
        };
      }

      if (!!companyId) {
        companyName = (this.customComplianceCompanies?.find((co) => co.companyId === companyId).name)?.split(' ').join('_');
      }

      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = !!companyName ?
        `Compliance_And_Affiliations_Report-${companyName}-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}` :
        `Compliance_And_Affiliations_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.npoComplianceAffiliationReportDownloadEndpoint,
        {
          paginationOptions: filteredPaginationOptions,
          companyId
        }
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  /**
   * Get Import Summary Report Data
   *
   * @param paginationOptions pagination options
   * @returns report data
   */
  getImportSummaryTable (
    paginationOptions: PaginationOptions<ImportSummaryModel>
  ) {
    return this.reportingResources.getImportSummaryReportData(
      paginationOptions,
      this.drilldownRowId
    );
  }

  /**
   * Get Import History Report Data
   *
   * @param paginationOptions pagination options
   * @returns report data
   */
  getImportHistoryTable (
    paginationOptions: PaginationOptions<ImportHistoryModel>
  ) {
    return this.reportingResources.getImportHistoryReportData(
      paginationOptions
    );
  }

  async getNposWithDisbursementSuspended (
    paginationOptions: PaginationOptions<NpoWithDisbursementSuspended>
  ) {
    try {
      const response = await this.reportingResources.getNposWithDisbursementSuspended(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getNposWithDisbursementSuspendedDownload (
    paginationOptions: PaginationOptions<NpoWithDisbursementSuspended>,
    format: TableDataDownloadFormat
  ) {
    try {
      const adaptedPaginationOptions: PaginationOptions<NpoWithDisbursementSuspendedDownload> = {} as PaginationOptions<NpoWithDisbursementSuspendedDownload>;
      adaptedPaginationOptions.filterColumns = [];
      adaptedPaginationOptions.returnAll = true;
      adaptedPaginationOptions.sortColumns = [{
        columnName: 'nonprofit.name',
        sortAscending: true
      }];
      paginationOptions.filterColumns.forEach((column) => {
        if (column.columnName === 'nonprofitAdmin.email') {
          adaptedPaginationOptions.filterColumns.push({
            columnName: 'activeAdminEmail',
            filters: [{
              filterType: 'cn',
              filterValue: column.filters[0].filterValue
            }]
          });

          return false;
        } else {
          return true;
        }
      });
      const allOptions = {
        ...adaptedPaginationOptions,
        ...paginationOptions
      };

      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Npos_With_Disbursement_Suspended-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        allOptions,
        format,
        fileName,
        this.reportingResources.organizationsWithDisbursementSuspendedDownload,
        null
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getReissueCheckRequests (
    paginationOptions: PaginationOptions<CheckReissueDisplayModel>
  ) {
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [
          this.companyIdColumn,
          this.checkReissueStatusIdColumn
        ]
      );
    try {
      const response = await this.reportingResources.getReissueCheckRequests(
        adaptedParams.paginationOptions,
        adaptedParams.params[this.companyIdColumn],
        adaptedParams.params[this.checkReissueStatusIdColumn]
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadReissueCheckRequests (
    paginationOptions: PaginationOptions<CheckReissueDisplayModel>,
    format: TableDataDownloadFormat
  ) {
    try {
      const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [
          this.companyIdColumn,
          this.checkReissueStatusIdColumn
        ]
      );
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Reissue_Check_Requests-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.reissueCheckRequestsDownloadEndpoint,
        {
          paginationOptions: adaptedParams.paginationOptions,
          companyId: adaptedParams.params[this.companyIdColumn],
          statusId: adaptedParams.params[this.checkReissueStatusIdColumn]
        }
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getActiveNonprofitsEFGReportRegAuthAndCountry (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    try {
      const response = await this.reportingResources.getActiveNonprofitsEFGReportRegAuthAndCountry(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getActiveNposByEFGCountryAndRegAuthDownload (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Active_Npos_By_EFG_Country_And_RegAuthority-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.activeNonprofitsEFGReportDownload,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getActiveNonprofitsEFGReportByRegistrationAuthority (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    try {
      const response = await this.reportingResources.getActiveNonprofitsEFGReportByRegistrationAuthority(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getActiveNonprofitsEFGReportByRegistrationAuthorityDownload (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Active_Nonprofits_EFG_By_Registration_Authority-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.activeNonprofitsEFGReportByRegistrationAuthorityDownload,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getActiveNonprofitsEFGReportByCountry (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    try {
      const response = await this.reportingResources.getActiveNonprofitsEFGReportByCountry(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getActiveNonprofitsEFGReportByCountryDownload (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Active_Nonprofits_EFG_Report_By_Country-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.activeNonprofitsEFGReportByCountryDownload,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getActiveNonprofitsEFGReportByProcessor (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    try {
      const response = await this.reportingResources.getActiveNonprofitsEFGReportByProcessor(
        paginationOptions
      );

      return response;
    } catch (e) {
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getActiveNonprofitsEFGReportByProcessorDownload (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Active_Nonprofits_EFG_Report_By_Processor-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.activeNonprofitsEFGReportByProcessingPartnerDownload,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getAllCompliances () {
    const response = await this.reportingResources.getAllCompliances();
    this.setAllCompliances(response.data);
  }

  async downloadComplianceReport (
    paginationOptions: PaginationOptions<ComplianceAndAffirmationCSV>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Compliance_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.complianceReportDownload,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getCurrentEfgReportDateTime (): Promise<string> {
    const paginationOptions: PaginationOptions<JobsReportModel> = {
      filterColumns: [
        {
          columnName: 'jobName',
          filters: [
            {
              filterType: 'eq',
              filterValue: 'PopulateEFGReportTables'
            }
          ]
        }
      ],
      orFilterColumns: [],
      rowsPerPage: 10,
      pageNumber: 1,
      sortColumns: [{
        columnName: 'jobName',
        sortAscending: true
      }],
      retrieveTotalRecordCount: true,
      returnAll: false
    };
    const response = await this.reportingResources.getJobsReport(paginationOptions);

    return response.data.records[0]?.lastExecutedDate || '';
  }

  async getEmailReport (
    paginationOptions: PaginationOptions<EmailLogReportModel>,
    nonprofitId?: number
  ) {
    const adaptedParams = this.getPaginationOptionsForEmailLog(
      paginationOptions,
      nonprofitId
    );
    try {
      const response = await this.reportingResources.getEmailReport(
        adaptedParams.paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getEmailReportForDownload (
    paginationOptions: PaginationOptions<EmailLogReportModel>,
    format: TableDataDownloadFormat,
    nonprofitId?: number
  ) {
    const adaptedParams = this.getPaginationOptionsForEmailLog(
      paginationOptions,
      nonprofitId
    );
    const payload = {
      paginationOptions: adaptedParams.paginationOptions,
      nonprofitId
    };
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Email_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.emailReportForDownloadEndpoint,
        payload
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getEmailPreview (emailId: number) {
    try {
      const response = await this.reportingResources.getEmailPreview(emailId);

      return response.data;
    } catch(e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getNonprofitsBasedOnVettingExpiredDay (
    paginationOptions: PaginationOptions<VettingExpirationReport>
  ) {
    let vetAuthId: number;
    let expDays: number;
    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((column) => {
      if (column.columnName === 'vettingAuthorityId') {
        vetAuthId = column.filters[0].filterValue as number;

        return false;
      } else if (column.columnName === 'expireDays') {
        expDays = column.filters[0].filterValue as number;

        return false;
      } else {
        return true;
      }
    });

    try {
      const response = await this.reportingResources.getNonprofitsBasedOnVettingExpiredDay(
        vetAuthId,
        expDays,
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getVettingExpirationDownload (
    paginationOptions: PaginationOptions<VettingExpirationReport>,
    format: TableDataDownloadFormat
  ) {
    let vetAuthId: number;
    let expDays: number;
    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((column) => {
      if (column.columnName === 'vettingAuthorityId') {
        vetAuthId = column.filters[0].filterValue as number;

        return false;
      } else if (column.columnName === 'expireDays') {
        expDays = column.filters[0].filterValue as number;

        return false;
      } else {
        return true;
      }
    });

    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Vetting_Expiration_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.vettingExpirationsDownloadEndpoint,
        {
          vetAuthId,
          expDays,
          paginationOptions
        }
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getVettingRequestsForReporting (
    paginationOptions: PaginationOptions<VettingRequestForReportingModel>
  ) {
    let activeOnly = false;
    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((option) => {
      if (option.columnName === 'statusOption') {
        if (option.filters[0].filterValue === VR_REPORT_STATUS_OPTION.ALL_ACTIVE_REQUESTS) {
          activeOnly = true;
        }

        return false;
      } else {
        return true;
      }
    });
    if (activeOnly) {
      paginationOptions.filterColumns = [
        ...paginationOptions.filterColumns,
        {
          columnName: 'requestStatusId',
          filters: [
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.REQUEST_SUBMITTED
            },
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.IN_PROGRESS
            },
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.ROUTED_TO_VETTING_AUTHORITY
            },
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.ON_HOLD_PENDING_BILLING
            }
          ]
        }
      ];
    }
    try {
      const response = await this.reportingResources.getVettingRequestsForReporting(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadVettingRequestsForReporting (
    options: PaginationOptions<VettingRequestForReportingModel>,
    format: TableDataDownloadFormat
  ) {
    let activeOnly = false;
    options.filterColumns = options.filterColumns.filter((option) => {
      if (option.columnName === 'statusOption') {
        if (option.filters[0].filterValue === VR_REPORT_STATUS_OPTION.ALL_ACTIVE_REQUESTS) {
          activeOnly = true;
        }

        return false;
      } else {
        return true;
      }
    });
    if (activeOnly) {
      options.filterColumns = [
        ...options.filterColumns,
        {
          columnName: 'requestStatusId',
          filters: [
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.REQUEST_SUBMITTED
            },
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.IN_PROGRESS
            },
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.ROUTED_TO_VETTING_AUTHORITY
            },
            {
              filterType: 'eq',
              filterValue: VettingRequestStatusId.ON_HOLD_PENDING_BILLING
            }
          ]
        }
      ];
    }
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Vetting_Requests_For_Reporting-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        options,
        format,
        fileName,
        this.reportingResources.downloadVettingRequestsForReportingEndpoint,
        null
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async failedPaymentReturnedChecksReport (
    paginationOptions: PaginationOptions<FailedPaymentReturnedChecksReportDisplayReturnModel>
  ) {
    try {
      const response = await this.reportingResources.failedPaymentReturnedChecksReport(
        paginationOptions
      );

      return response;
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async failedPaymentReturnedChecksReportDownload (
    paginationOptions: PaginationOptions<FailedPaymentReturnedChecksReportDisplayReturnModel>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Failed_Payment_Returned_Checks_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.failedPaymentReturnedChecksReportDownloadEndpoint,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getBankCustomAttributesReport (
    paginationOptions: PaginationOptions<BankCustomAttributesReportReturnModel>,
    showIBAN: boolean
  ) {
    if (!showIBAN) {
      const ibanFilterColumn: FilterColumn<BankCustomAttributesReportReturnModel> = {
        columnName: 'attributeName',
        filters: [
          {
            filterType: 'ne',
            filterValue: 'IBAN'
          }
        ]
      };
      paginationOptions.filterColumns = [
        ...paginationOptions.filterColumns,
        ibanFilterColumn
      ];
    }
    try {
      const response = await this.reportingResources.getBankCustomAttributesReport(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getBankCustomAttributesReportDownload (
    paginationOptions: PaginationOptions<BankCustomAttributesReportReturnModel>,
    format: TableDataDownloadFormat,
    showIBAN: boolean
  ) {
    if (!showIBAN) {
      const ibanFilterColumn: FilterColumn<BankCustomAttributesReportReturnModel> = {
        columnName: 'attributeName',
        filters: [
          {
            filterType: 'ne',
            filterValue: 'IBAN'
          }
        ]
      };
      paginationOptions.filterColumns = [
        ...paginationOptions.filterColumns,
        ibanFilterColumn
      ];
    }
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Bank_Custom_Attributes_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        paginationOptions,
        format,
        fileName,
        this.reportingResources.bankCustomAttributesReportDownloadEndpoint,
        null
      );
    } catch (e: any) {
      console.error(e);
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async fetchAllCompanies () {
    const response = await this.reportingResources.fetchAllCompanies();
    this.setAllCompanies(response);
    this.setCompaniesForInput(response);
  }

  async uploadCharityIdList (charityIds: number[]) {
    try {
      await this.reportingResources.uploadCharityIdList(charityIds);
      this.notifier.success(this.i18n.translate(
        'common:notificationSuccessUploadingFile',
        {},
        'Successfully uploaded file'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorUploadingFile',
        {},
        'There was an error uploading the file'
      ));
    }
  }

  async getBBINonprofitSubsectorReport (
    paginationOptions: PaginationOptions<BbiNonprofitSubsectorReportModel>
  ) {
    const payload: BbiNonprofitSubsectorReportPayload = this.getAdaptedBbiSubsctorCodePayload(paginationOptions);
    try {
      return await this.reportingResources.getBBINonprofitSubsectorReport(
        payload
      );
    } catch (e) {
      this.notifier.error(this.errorRetrievingDataText);
      throw e;
    }
  }

  async downloadBBINonprofitSubsectorReport (
    paginationOptions: PaginationOptions<BbiNonprofitSubsectorReportModel>,
    format: TableDataDownloadFormat
  ) {
    const payload: BbiNonprofitSubsectorReportPayload = this.getAdaptedBbiSubsctorCodePayload(paginationOptions);
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `BBI_Nonprofit_Subsector_Report-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        this.reportingResources.downloadBBINonprofitSubsectorReportEndpoint,
        {
          paginationOptions: payload.paginationOptions,
          earliestPaymentDate: payload.earliestPaymentDate,
          latestPaymentDate: payload.latestPaymentDate,
          exportReportType: format
        }
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getNonprofitClassificationMapReport (
    paginationOptions: PaginationOptions<NonprofitClassificationHierarchyRequestModel>
  ) {
    try {
      const response = await this.reportingResources.getNonprofitClassificationMapReport(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getNonprofitClassificationMapDownload (
    paginationOptions: PaginationOptions<NonprofitClassificationHierarchyRequestModel>
  ) {
    try {
        const response = await this.reportingResources.getNonprofitClassificationMapReport(
          paginationOptions
        );

      return this.fileService.convertObjectArrayToCSVString(response.data.records);
    } catch (e) {
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getVettingApplicationsSubmittedOrCompletedReportDisplay (
    paginationOptions: PaginationOptions<VettingApplicationsSubmittedOrCompletedReportDisplayModel>
  ) {
    let startDate;
    let endDate;
    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((column) => {
      if (column.columnName === 'applicationSubmittedDate') {
        const filterType = column.filters[0].filterType as FilterModalTypes;
        const dates = this.filterHelperService.getClientSideRelativeDates(
          filterType
        );
        if (dates) {
          startDate = dates[0];
          endDate = dates[1];
        }

        return false;
      } else {
        return true;
      }
    });
    const response = await this.reportingResources.getVettingApplicationsSubmittedOrCompletedReportDisplay(
      paginationOptions,
      startDate,
      endDate
    );

    return response;
  }

  async downloadVettingApplicationsSubmittedOrCompletedReportDisplay (
    paginationOptions: PaginationOptions<VettingApplicationsSubmittedOrCompletedReportDisplayModel>,
    format: TableDataDownloadFormat
  ) {
    let startDate;
    let endDate;
    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((column) => {
      if (column.columnName === 'applicationSubmittedDate') {
        const filterType = column.filters[0].filterType as FilterModalTypes;
        const dates = this.filterHelperService.getClientSideRelativeDates(
          filterType
        );
        if (dates) {
          startDate = dates[0];
          endDate = dates[1];
        }

        return false;
      } else {
        return true;
      }
    });
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Vetting_Application_Submitted_Or_Completed-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        null,
        format,
        fileName,
        'api/ReportingController/SubmittedOrCompletedVettingApplicationsDownload',
        {
          paginationOptions,
          startDate,
          endDate,
          exportReportType: format
        }
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getBBISubsectorInfoList () {
    const response = await this.reportingResources.getBBISubsectorInfoList();
    this.setBbiSubsectorData(response);
  }

  async getRevokedNonprofitsSubdivisionsList () {
    const response = await this.reportingResources.getRevokedNonprofitsSubdivisionsList();
    this.setRevokedNonprofitsSubdivisionsData(response.revokedNonprofitsSubdivisions);
  }

  async queueActiveNonprofitsEFGReport (payload: ActiveNpoByEFGLinkPayload) {
    try {
      const response = await this.reportingResources.queueActiveNonprofitsEFGReport(payload);

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorDownloadingFile',
        {},
        'There was an error downloading the file'
      ));

      return null;
    }
  }

  async getYCProcessingPartnerNames () {
    const response = await this.reportingResources.getYCProcessingPartnerNames();

    return response;
  }

  async getEligibilityForGivingOverrides (
    paginationOptions: PaginationOptions<NonprofitEligibileForGivingOverridesDto>
  ) {
    try {
      const response = await this.reportingResources.getEligibilityForGivingOverrides(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async downloadEligibilityForGivingOverrides (
    options: PaginationOptions<NonprofitEligibileForGivingOverridesDto>,
    format: TableDataDownloadFormat
  ) {
    try {
      const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';
      const fileName = `Nonprofit_Eligibility_For_Giving_Overrides-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

      return this.assetManagementService.downloadCsvOrExcel(
        options,
        format,
        fileName,
        this.reportingResources.nonprofitEligibileForGivingOverridesDownload,
        null
      );
    } catch (e: any) {
      if (e.error.message) {
        this.notifier.error(e.error.message);
      } else {
        this.notifier.error(this.errorRetrievingDataText);
      }

      return null;
    }
  }

  async getRevokedNonprofitsForDisplay (
    paginationOptions: PaginationOptions<RevokedNonprofitDto>
  ) {
    let revokedByStateCode;

    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((column) => {
      if (column.columnName === 'revokedByStateCode') {
        revokedByStateCode = column.filters[0].filterValue as string;

        return false;
      } else {
        return true;
      }
    });

    return await this.getRevokedNonprofits(paginationOptions, revokedByStateCode);
  }

  async getRevokedNonprofitsForDownload (
    paginationOptions: PaginationOptions<RevokedNonprofitDto>,
    format: TableDataDownloadFormat
  ) {
    let revokedByStateCode;

    paginationOptions.filterColumns = paginationOptions.filterColumns.filter((column) => {
      if (column.columnName === 'revokedByStateCode') {
        revokedByStateCode = column.filters[0].filterValue as string;

        return false;
      } else {
        return true;
      }
    });

    return await this.getRevokedNonprofits(paginationOptions, revokedByStateCode, format);
  }

  private async getRevokedNonprofits (
    paginationOptions: PaginationOptions<RevokedNonprofitDto>,
    revokedByStateCode?: string,
    format?: TableDataDownloadFormat
  ) {
    if (format) {//Download
      try {
        const extension = format === TableDataDownloadFormat.XLSX ? 'xlsx' : 'csv';

        const fileName = `Revoked_Nonprofits-${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.${extension}`;

        return this.assetManagementService.downloadCsvOrExcel(
          undefined,
          format,
          fileName,
          this.reportingResources.revokedNonprofitsDownload,
          {
            paginationOptions,
            revokedByStateCode,
            exportReportType: format
          }
        );
      } catch (e: any) {
        if (e.error.message) {
          this.notifier.error(e.error.message);
        } else {
          this.notifier.error(this.errorRetrievingDataText);
        }

        return null;
      }
    } else {//Display
      try {
        const response = await this.reportingResources.getRevokedNonprofits(
          paginationOptions,
          revokedByStateCode
        );

        return response;
      } catch (e) {
        this.notifier.error(this.errorRetrievingDataText);

        return null;
      }
    }
  }
}



export class YcCharityIdValidationModel {
  @IsNumber()
  @Required()
  'YC Charity Id': number;
}
