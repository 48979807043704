import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';
import '@yourcause/common';
import { AppSharedModule } from './app/app.shared.module';


if (environment.production) {
  enableProdMode();
}

if (environment.addGoogleTagManagerProd) {
  addGoogleTagManagerProd();
}

if (environment.addGoogleTagManagerNotProd) {
  addGoogleTagManagerNotProd();
}

if (environment.addMetaFacebookPixel) {
  addMetaFacebookPixel();
}

if (environment.addLinkedInInsights) {
  addLinkedInInsights();
}

function addGoogleTagManagerProd () {
  const script = document.createElement('script');

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WQZTBFL9');`;

  document.head.appendChild(script);
}

function addGoogleTagManagerNotProd () {
  const script = document.createElement('script');

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-54469WQP');`;

  document.head.appendChild(script);

  const noScript = document.createElement('noscript');

  noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-54469WQP"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.body.prepend(noScript);
}

function addMetaFacebookPixel () {
  const meta = document.createElement('meta');

  meta.name = 'facebook-domain-verification';

  meta.content = '9r4lv1wuaym7j7ukjc9a6m8t6iy1p5';

  document.head.appendChild(meta);

  const script = document.createElement('script');

  script.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '5373970592640304');
  fbq('track', 'PageView');`;

  document.head.appendChild(script);
}

function addLinkedInInsights () {
  const scriptOne = document.createElement('script');

  scriptOne.type = 'text/javascript';

  scriptOne.innerHTML = `_linkedin_partner_id = "6449946"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);`;

  document.head.appendChild(scriptOne);

  const scriptTwo = document.createElement('script');

  scriptTwo.type = 'text/javascript';

  scriptTwo.innerHTML = `(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`;

  document.head.appendChild(scriptTwo);

  const noScript = document.createElement('noscript');

  noScript.innerHTML = `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=6449946&fmt=gif" />`;

  document.head.appendChild(noScript);
}

export function main () {
  return platformBrowserDynamic().bootstrapModule(AppSharedModule);
}

main();
